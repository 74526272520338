import React from "react";
import { Modal } from "antd";
import { Container, Content, ImageContainer, CloseButton } from "./styled";
import Typography from "../../../components/Typography";
import Image from "next/image";
import useWindowDimentions from "../../../hooks/useWindowDimensions";

export default function ModalContactSuccess({ open = false, onRequestClose }) {
  const { isSmallDevice } = useWindowDimentions();

  if (!open) return null;

  const handleClose = (e) => {
    // Prevent event bubbling
    e?.stopPropagation?.();
    
    if (onRequestClose) {
      onRequestClose();
    }
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      closable={false}
      centered
      footer={null}
      width="90%"
      style={{ 
        maxWidth: "900px"
      }}
      bodyStyle={{
        backgroundColor: "white",
        padding: 0,
        border: "none",
        borderRadius: "4px",
        overflow: "hidden"
      }}
      destroyOnClose={true}
      maskClosable={true}
      mask={true}
      keyboard={true}
      getContainer={document.body}
      wrapClassName="success-modal-wrap"
      zIndex={1060}
    >
      <Container>
        <CloseButton onClick={handleClose} aria-label="Close modal">
          ✕
        </CloseButton>
        <Content>
          <Typography
            textAlign="center"
            color="darkGray2"
            fontSize={isSmallDevice ? "1.8rem" : "2.5rem"}
            fontWeight="bold"
            fontType="header"
            margin={[0, 0, 20, 0]}
          >
            Thank you for contacting us!
          </Typography>

          <Typography
            textAlign="center"
            fontSize={isSmallDevice ? "1rem" : "1.5rem"}
            margin={[0, 0, 20, 0]}
          >
            We look forward to putting a smile on your face by designing a
            perfect customized storage solution tailored especially for you!
          </Typography>
          <ImageContainer>
            <Image
              src="/images/contact/contact-success.jpg"
              width={800}
              height={424}
              alt="Contact Success"
              layout="responsive"
            />
          </ImageContainer>
        </Content>
        <Typography
          textAlign="center"
          fontSize="0.85rem"
          color="borderColor1"
          margin={[10, 0, 0, 0]}
        >
          We&apos;ll be contacting you to discuss your needs and confirm a time
          that is convenient for you to have your Personal Design Professional
          meet with you for a FREE, in-home or in-office Consultation.
        </Typography>
      </Container>
    </Modal>
  );
}
