import styled from "styled-components";

export const Container = styled.div`
  max-width: calc(590px + 150px);
  margin: 0px auto;
  padding: 40px calc(150px / 2);

  @media only screen and (max-width: 768px) {
    padding: 48px calc(60px / 2);
  }
`;

export const ImageContainer = styled.div`
  margin: 0px calc(-210px / 2);
  @media only screen and (max-width: 768px) {
    margin: 0px calc(-140px / 2);
  }
`;

export const Content = styled.div`
  padding: 0px 20px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: calc(100%);
    height: calc(100% - 20px);
    top: 0px;
    left: 0px;
    border: 3px solid #762738;
    z-index: 1;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border: none;
  background: #762738;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  z-index: 2;
  transition: background-color 0.3s ease;

  &:hover {
    background: #8f3146;
  }

  @media only screen and (max-width: 768px) {
    top: 10px;
    right: 10px;
    width: 32px;
    height: 32px;
    font-size: 16px;
  }
`;
